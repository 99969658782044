import React from "react";
import {styled} from "@reside/ui";
import {debounce} from "lodash";
import {SubtitleNode} from "@reside/forms";
import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";

type Props = Readonly<{
  isFocused?: boolean;
  node: SubtitleNode;
}> &
  AtomProp;

export const EditableSubtitle = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
      styledElement={StyledSubtitleCSS}
    />
  );
};

const StyledSubtitleCSS = styled.div`
  .bangle-editor > p {
    font-weight: 600;
    margin-top: 0.625em;
    margin-bottom: 0.625em;
    font-size: 2em;
    line-height: 1.5em;
    padding: 0px 7px;
  }

  .ant-tag {
    font-size: 1em;
    padding: 0.3em;
  }
`;
