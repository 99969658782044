import MarkdownIt from "markdown-it";
import Token from "markdown-it/lib/token";
import {AnswerType} from "../../model/markdown/MarkdownEditorModel";

// The if statements are for now ignored from parser
const ANSWER_REGEX = /\[\[(?!IF).*?]]/g;

const getMatches = (token: Token) => {
  return token.content.match(ANSWER_REGEX);
};

const getAnswerToken = ({
  id,
  value,
  label = "",
}: {
  id: string;
  value: string;
  label?: string;
}) => {
  const token = new Token("answer", "", 0);
  token.attrs = [
    ["id", id],
    ["value", value],
    ["label", label],
  ];
  token.content = "";
  token.children = [];

  return token;
};

const getTextToken = ({content}: {content: string}) => {
  const text = new Token("text", "", 0);

  text.content = content;
  const token = new Token("inline", "", 0);
  token.level = 1;
  token.children = [text];

  return token;
};

const getTokens = (
  matches: string[],
  input: string,
  answers: Record<string, AnswerType>,
) =>
  matches.reduce(
    (acc: {rest: string; tokens: Token[]}, match, index) => {
      const [before, after] = acc.rest.split(match);
      const rest = after ?? "";

      const id = match.replace("[[", "").replace("]]", "");
      const isLast = index >= matches.length - 1;

      if (isLast) {
        return {
          rest,
          tokens: [
            ...acc.tokens,
            getTextToken({content: before}),
            getAnswerToken({
              id,
              value: answers[id]?.value,
              label: answers[id]?.label,
            }),
            getTextToken({content: rest}),
          ],
        };
      }

      return {
        rest,
        tokens: [
          ...acc.tokens,
          getTextToken({content: before}),
          getAnswerToken({
            id,
            value: answers[id]?.value,
            label: answers[id]?.label,
          }),
        ],
      };
    },
    {rest: input, tokens: []},
  ).tokens;

export const markdownAnswers = (answers: Record<string, AnswerType> = {}) => (
  md: MarkdownIt,
) => {
  // push a new rule after other rules are applied
  md.core.ruler.push("answer", state => {
    let {tokens} = state;

    tokens.forEach((token, i) => {
      const matches = getMatches(token);

      if (matches) {
        token?.children?.forEach((childToken, i) => {
          const matches = getMatches(childToken);

          if (matches) {
            // Mutation because there is only mutation API
            token?.children?.splice(
              i,
              1,
              ...getTokens(matches, childToken.content, answers),
            );
          }
        });
      }
    });

    return false;
  });
};
