import React from "react";
import {BangleEditor} from "@bangle.dev/react";
import {
  StaticMenu,
  BoldButton,
  ItalicButton,
  Menu,
  MenuGroup,
} from "@bangle.dev/react-menu";
import {AnswerSelect, TreeDataItem} from "./AnswerSelect";

type Props = Readonly<{
  hasTopMenu?: boolean;
  treeData: TreeDataItem[];
  editor: typeof BangleEditor;
}>;

export const MarkdownEditorMenu = ({hasTopMenu, treeData, editor}: Props) => {
  if (hasTopMenu) {
    return (
      <StaticMenu
        editor={editor}
        // We have a render prop to allow for updating
        // menu whenever editors state changes
        renderMenu={() => (
          <Menu
            style={{
              "--bangle-menu-button-active-color": "#1890ff",
              backgroundColor: "#f0f2f5",
              color: "black",
            }}
          >
            <MenuGroup>
              <BoldButton />
              <ItalicButton />
              <AnswerSelect treeData={treeData} />
            </MenuGroup>
          </Menu>
        )}
      />
    );
  } else {
    return <></>;
  }
};
