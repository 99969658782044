import React from "react";
import {Button, message, Tooltip, Typography, Popconfirm} from "antd";
import {useMutation} from "react-query";
import {CloudUploadOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";

import {logError} from "../../logging";
import {branchNameAtom, hasModifiedFilesSelector} from "../../model/repository";
import {branchConfig, previewBranchName, publishBranchName} from "../../config";
import {useCompareChanges} from "../../model/repository/useCompareChanges";
import {usePushCommitMutation} from "../../model/repository/usePushCommitMutation";
import {mergeBranch} from "../../services/GithubService";

const {Link, Text} = Typography;
const {publishAppUrl, publishEnvName} = branchConfig;

export const PublishButton = () => {
  const branchName = useRecoilValue(branchNameAtom);
  const hasModifiedFiles = useRecoilValue(hasModifiedFilesSelector);

  const {
    data: hasChangesToPublish,
    isFetching: isComparingPublishChanges,
    refetch: refetchComparePublishChanges,
  } = useCompareChanges({
    toBranch: publishBranchName,
  });

  const {refetch: refetchComparePreviewChanges} = useCompareChanges({
    toBranch: previewBranchName,
  });

  const {
    mutate: createAndMergePullRequest,
    isLoading: isCreatingAndMergingPullRequest,
  } = useMutation(
    async () => {
      // TODO: rebase on publish branch

      await mergeBranch({
        fromBranch: branchName,
        toBranch: publishBranchName,
      });
    },
    {
      onSuccess: () => {
        refetchComparePublishChanges();
        message.success(
          <>
            Your changes are <Link href={publishAppUrl}>ready to preview</Link>!
            🚀
          </>,
        );
      },
      onError: (error: any) => {
        logError(error);
        if (error?.data?.conflicted) {
          message.error("Cannot merge due to conflict.");
          // TODO: wipe develop?
        }
      },
    },
  );

  const {mutate: pushCommit, isLoading: isPushingCommit} =
    usePushCommitMutation();

  const isLoading = isPushingCommit || isCreatingAndMergingPullRequest;
  const canPublish = hasChangesToPublish || hasModifiedFiles;

  return (
    <Tooltip
      title="Nothing to publish. Please make & save some changes first."
      {...(isComparingPublishChanges || hasChangesToPublish || hasModifiedFiles
        ? {visible: false}
        : {})}
    >
      <Popconfirm
        title={
          <>
            You are about to deploy branch <Text code>{branchName}</Text> to{" "}
            {publishEnvName}. Please confirm.
          </>
        }
        disabled={!canPublish}
        onConfirm={
          hasModifiedFiles
            ? () =>
                pushCommit({
                  onSuccess: () => {
                    createAndMergePullRequest();
                    /**
                     * Invalidate the stalled state of publishable changes.
                     */
                    refetchComparePreviewChanges();
                  },
                })
            : () => createAndMergePullRequest()
        }
        okText="Yes"
        cancelText="No"
      >
        <Button
          icon={<CloudUploadOutlined />}
          loading={isLoading}
          disabled={!canPublish}
        >
          {isCreatingAndMergingPullRequest
            ? "Merging pull-request..."
            : isPushingCommit
            ? "Pushing commit..."
            : `Publish on ${publishEnvName}`}
        </Button>
      </Popconfirm>
    </Tooltip>
  );
};
