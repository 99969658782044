import {
  atom,
  RecoilState,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {flatten, groupBy, sortBy} from "lodash";

import {constantCommonComponentsDirName, TemplateType} from "../../constants";
import {
  GroupNode,
  SourceSection,
  SourceSectionGroup,
  SourceSlide,
  TemplateNodes,
} from "../schemaTypes";
import {
  atomizedTemplateAtom,
  atomizeNodes,
  isTemplateDirtyAtom,
  templateTypeAtom,
} from "../template";
import {activeSlidePathAtom} from "../editor";

import {
  createEmptySection,
  createEmptySectionGroup,
  createEmptySlide,
  createSourceImport,
  isGroupNode,
} from "../../utils";

/**
 * ATOMS
 */
export const libraryComponentsAtom = atom<ReadonlyArray<LibraryComponents>>({
  key: "libraryComponents",
  default: [],
});

/**
 * SELECTORS
 */
const templateLibraryComponentsSelector = selector({
  key: "templateLibraryComponentsSelector",
  get: ({get}) => {
    const libraryComponents = get(libraryComponentsAtom);
    const templateType = get(templateTypeAtom);

    return libraryComponents.find(
      component => component.templateType === templateType,
    )?.components;
  },
});

export const componentLibraryByTypeSelector = selector({
  key: "componentLibraryByType",
  get: ({get}) => {
    const templateLibraryComponents = get(templateLibraryComponentsSelector);

    const groupedByTemplateType = groupBy(templateLibraryComponents, "type");

    const sortedComponents = flatten(
      Object.values(groupedByTemplateType),
    ).filter(node => !isGroupNode(node));

    return sortBy(sortedComponents, "type");
  },
});

export const groupIdsByTypeSelector = selector({
  key: "groupIdsByType",
  get: ({get}) => {
    const atomizedTemplate = get(atomizedTemplateAtom);

    const sectionGroups = atomizedTemplate.map(sectionGroupAtom =>
      get(sectionGroupAtom),
    );
    const sections = sectionGroups
      .flatMap(sectionGroup => sectionGroup.children)
      .map(get) as any as TemplateNodes[];

    const slides = sections
      .flatMap(section => section.children)
      .map(get) as any as TemplateNodes[];

    return {
      sectionGroupIds: sectionGroups.map(({id}) => id),
      sectionIds: sections.map(({id}) => id),
      slideIds: slides.map(({id}) => id),
    };
  },
});

export const groupedComponentLibraryMenuByTypeSelector = selector({
  key: "groupedComponentLibraryMenuByType",
  get: ({get}) => {
    const templateLibraryComponents = get(templateLibraryComponentsSelector);

    return groupBy(templateLibraryComponents?.filter(isGroupNode), "type");
  },
});

export const libraryGroupMenuSelector = selector({
  key: "libraryGroupMenuSelector",
  get: ({get}) => {
    const {
      slide = [],
      section = [],
      "section-group": sectionGroup = [],
    } = get(groupedComponentLibraryMenuByTypeSelector);
    const {slideIds, sectionIds, sectionGroupIds} = get(groupIdsByTypeSelector);

    return [
      {key: "slide", items: slide as GroupComponents, existingIds: slideIds},
      {
        key: "section",
        items: section as GroupComponents,
        existingIds: sectionIds,
      },
      {
        key: "section-group",
        items: sectionGroup as GroupComponents,
        existingIds: sectionGroupIds,
      },
    ].map(({key, items, existingIds}) => ({
      key,
      items: items.map(node => ({
        node,
        disabled: existingIds.includes(node.id),
      })),
    }));
  },
});

export const componentLibraryMenuByTypeSelector = selector({
  key: "libraryComponentsMenuByType",
  get: ({get}) => {
    const groupedComponentLibraryMenuByType = get(
      groupedComponentLibraryMenuByTypeSelector,
    );

    const libraryComponentsMenuByType = flatten(
      Object.values(groupedComponentLibraryMenuByType),
    );

    return sortBy(libraryComponentsMenuByType, node => node.type);
  },
});

export const isCommonComponentUsedSelector = selectorFamily({
  key: "isCommonComponentUsedSelector",
  get:
    (nodeId: string) =>
    ({get}) => {
      const atomizedTemplate = get(atomizedTemplateAtom);

      const atomsContainNode = (
        atoms: ReadonlyArray<RecoilState<TemplateNodes>>,
      ): boolean => {
        return atoms.some(atom => {
          const node = get(atom) as any;

          if (node.id === nodeId) {
            return true;
          }

          return node.children
            ? atomsContainNode(node.children)
            : node.items
            ? atomsContainNode(node.items)
            : node.reference?.choices
            ? atomsContainNode(node.reference.choices)
            : undefined;
        });
      };

      return atomsContainNode(atomizedTemplate);
    },
});

/**
 * Check slide and its parents if there is a common import (not inherited).
 */
export const slideIsCommonImportSelector = selector({
  key: "slideIsCommonImport",
  get: ({get}) => {
    const activeSlidePath = [...get(activeSlidePathAtom)];

    let atoms = get(atomizedTemplateAtom);

    return activeSlidePath.some(index => {
      const node = get(atoms[index]);

      atoms = node.children;

      if (node.importedFrom) {
        return !node.importedFrom.inherit;
      }

      return false;
    });
  },
});

/**
 * Check slide and its parents if there is a constant common import.
 */
export const activeSlideIsConstantCommonImportSelector = selector({
  key: "activeSlideIsConstantCommonImport",
  get: ({get}) => {
    const activeSlidePath = get(activeSlidePathAtom);

    let atoms = get(atomizedTemplateAtom);

    return activeSlidePath.some(index => {
      const node = get(atoms[index]);

      atoms = node.children;

      if (node.importedFrom) {
        return node.importedFrom.src?.includes(constantCommonComponentsDirName);
      }

      return false;
    });
  },
});

/**
 * Check slide parents if there is a common import (not inherited).
 */
export const sectionGroupIsCommonImportSelector = selector({
  key: "sectionGroupIsCommonImport",
  get: ({get}) => {
    const activeSlidePath = [...get(activeSlidePathAtom)];

    activeSlidePath.pop();

    let atoms = get(atomizedTemplateAtom);

    return activeSlidePath.some(index => {
      const node = get(atoms[index]);

      atoms = node.children;

      if (node.importedFrom) {
        return !node.importedFrom.inherit;
      }

      return false;
    });
  },
});

type GroupComponents = (GroupNode & {fileName: string})[];
export type Components = (TemplateNodes & {
  fileName: string;
  filePath: string;
})[];

export type LibraryComponents = {
  templateType: TemplateType;
  components: Components;
};

/**
 * HOOKS
 */
export const useAddGroup = () => {
  const [sectionGroupIndex, sectionIndex] = useRecoilValue(activeSlidePathAtom);
  const [template, setTemplate] = useRecoilState(atomizedTemplateAtom);
  const [activeSectionGroup, setActiveSectionGroup] = useRecoilState(
    template[sectionGroupIndex],
  );
  const [activeSection, setActiveSection] = useRecoilState<TemplateNodes>(
    activeSectionGroup.children[sectionIndex],
  );

  const setIsTemplateDirty = useSetRecoilState(isTemplateDirtyAtom);

  return {
    addSlide: (slide?: SourceSlide & {filePath: string}) => {
      setActiveSection({
        ...activeSection,
        children: [
          ...activeSection.children,
          ...atomizeNodes([
            slide
              ? {
                  ...slide,
                  importedFrom: createSourceImport(slide.filePath),
                }
              : createEmptySlide(),
          ]),
        ],
      });
      setIsTemplateDirty(true);
    },
    addSection: (section?: SourceSection & {filePath: string}) => {
      setActiveSectionGroup({
        ...activeSectionGroup,
        children: [
          ...activeSectionGroup.children,
          ...atomizeNodes([
            section
              ? {
                  ...section,
                  importedFrom: createSourceImport(section.filePath),
                }
              : createEmptySection(),
          ]),
        ],
      });
      setIsTemplateDirty(true);
    },
    addSectionGroup: (
      sectionGroup?: SourceSectionGroup & {filePath: string},
    ) => {
      setTemplate([
        ...template,
        ...atomizeNodes([
          sectionGroup
            ? {
                ...sectionGroup,
                importedFrom: createSourceImport(sectionGroup.filePath),
              }
            : createEmptySectionGroup(),
        ]),
      ]);
      setIsTemplateDirty(true);
    },
  };
};
