import React from "react";
import {Form, Radio, Tooltip} from "antd";
import {isEqual} from "lodash";

import {VisibilityContextType} from "@reside/forms";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
  useUnsetFocusedNodePropertyValue,
} from "../../model/editor";
import {useIsResidentExperienceTemplateValue} from "../../model/template";

const BOTH_RADIO_KEY = "both";

export const VisibilityContextForm = () => {
  const isResidentExperienceTemplate = useIsResidentExperienceTemplateValue();

  const {
    /**
     * If the context is not set, the item is visible in resident experience only.
     */
    visibilityContext = [VisibilityContextType.RE],
  } = useFocusedNode() as any;

  const setVisibilityContext =
    useSetFocusedNodePropertyValue("visibilityContext");
  const unsetVisibilityContext =
    useUnsetFocusedNodePropertyValue("visibilityContext");

  /**
   * The radio input must have string as value, so we map the arrays to stink values.
   */
  const value = isEqual(visibilityContext, [VisibilityContextType.RE])
    ? VisibilityContextType.RE
    : isEqual(visibilityContext, [VisibilityContextType.PDF])
    ? VisibilityContextType.PDF
    : BOTH_RADIO_KEY;

  return isResidentExperienceTemplate ? (
    <Form.Item label="Visibility Context">
      <Radio.Group
        size="small"
        value={value}
        onChange={({target: {value}}) => {
          if (value === VisibilityContextType.PDF) {
            setVisibilityContext([VisibilityContextType.PDF]);
          } else if (value === VisibilityContextType.RE) {
            /**
             * RE is default, so rather than setting the value, we remove it.
             */
            unsetVisibilityContext();
          } else {
            setVisibilityContext([
              VisibilityContextType.RE,
              VisibilityContextType.PDF,
            ]);
          }
        }}
      >
        <Tooltip title="If selected, this item will be visible only in Resident Experience">
          <Radio.Button value={VisibilityContextType.RE}>
            Resident Experience
          </Radio.Button>
        </Tooltip>
        <Tooltip title="If selected, this item will be visible only in PDF">
          <Radio.Button value={VisibilityContextType.PDF}>PDF</Radio.Button>
        </Tooltip>
        <Tooltip title="If selected, this item will be visible in both PDF and Resident Experience">
          <Radio.Button value={BOTH_RADIO_KEY}>Both</Radio.Button>
        </Tooltip>
      </Radio.Group>
    </Form.Item>
  ) : null;
};
