import {useRecoilCallback, RecoilState, selectorFamily} from "recoil";
import {message} from "antd";

import {
  atomizedTemplateAtom,
  atomizeNodes,
  isTemplateDirtyAtom,
  templateNameAtom,
} from "../template";
import {
  createPdfNode,
  ensurePdfDir,
  pdfFileExists,
  writePdfFile,
} from "./pdfUtils";
import {TemplateNodes} from "../schemaTypes";

/**
 * SELECTORS
 */

/**
 * Check if PDF is from other (cloned) template.
 */
export const isCommonPdfSelector = selectorFamily({
  key: "sourceTemplate",
  get:
    (src: string) =>
    ({get}) => {
      const templateName = get(templateNameAtom);

      const [sourceTemplate] = src.split("/");

      return sourceTemplate !== templateName;
    },
});

/**
 * HOOKS
 */
export const useAppendPdfFile = () =>
  useRecoilCallback(({snapshot, set}) => async (file: File) => {
    const getLastSectionAtom = async (): Promise<
      RecoilState<TemplateNodes>
    > => {
      const sectionGroupAtoms = await snapshot.getPromise(atomizedTemplateAtom);

      const {children: sectionAtoms} = await snapshot.getPromise(
        sectionGroupAtoms[sectionGroupAtoms.length - 1],
      );

      return sectionAtoms[sectionAtoms.length - 1];
    };

    const templateName = await snapshot.getPromise(templateNameAtom);
    const lastSectionAtom = await getLastSectionAtom();
    const lastSection = await snapshot.getPromise(lastSectionAtom);

    if (templateName) {
      const pdfFileAlreadyExist = await pdfFileExists(templateName, file.name);

      if (pdfFileAlreadyExist) {
        const canOverwrite = window.confirm(
          "You are about to replace existing PDF with the same name. If you want to just add the PDF, rename it first.",
        );

        if (!canOverwrite) {
          return;
        }
      }

      try {
        await ensurePdfDir(templateName);
        await writePdfFile(file, templateName);
      } catch {
        message.error("Failed to write PDF file.");
        return;
      }

      if (!pdfFileAlreadyExist) {
        set(lastSectionAtom, {
          ...lastSection,
          children: [
            ...lastSection.children,
            ...atomizeNodes([createPdfNode(templateName, file.name) as any]),
          ],
        });
      }

      set(isTemplateDirtyAtom, true);

      message.success(
        pdfFileAlreadyExist
          ? "PDF file replaced."
          : "PDF file appended to the template.",
      );
    }
  });
