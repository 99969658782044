import React, {useState, useCallback} from "react";
import {useRecoilValue} from "recoil";
import {useMutation} from "react-query";
import {Button, message, Modal, Tooltip, Typography} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";

import {logError} from "../../logging";
import {branchNameAtom, hasModifiedFilesSelector} from "../../model/repository";
import {branchConfig, publishBranchName, previewBranchName} from "../../config";
import {useCompareChanges} from "../../model/repository/useCompareChanges";
import {usePushCommitMutation} from "../../model/repository/usePushCommitMutation";
import {resetHead} from "../../services/GitService";
import {mergeBranch} from "../../services/GithubService";

const {Link} = Typography;
const {previewAppUrl, previewEnvName} = branchConfig;

// TODO: if feature branch is pushed & push to preview branch failed, it's not possible to push to preview branch again
export const PreviewButton = () => {
  const branchName = useRecoilValue(branchNameAtom);
  const hasModifiedFiles = useRecoilValue(hasModifiedFilesSelector);
  const [isResetModalOpened, setIsResetModalOpened] = useState(false);

  const {
    data: hasChangesToPreview,
    isFetching: isComparingPreviewChanges,
    refetch: refetchCompareChanges,
  } = useCompareChanges({
    toBranch: previewBranchName,
  });

  const {refetch: refetchComparePublishChanges} = useCompareChanges({
    toBranch: publishBranchName,
  });

  const onSuccess = useCallback(() => {
    message.success(
      <>
        Your changes are <Link href={previewAppUrl}>ready to preview</Link>! 🚀
      </>,
    );
    refetchCompareChanges();
  }, [refetchCompareChanges]);

  const {mutate: resetPreviewHead, isLoading: isResettingHead} = useMutation(
    resetHead,
    {
      onSuccess,
      onError: () => {
        message.error("Failed to reset head.");
      },
    },
  );

  const {
    mutate: createAndMergePullRequest,
    isLoading: isCreatingAndMergingPullRequest,
  } = useMutation(
    () =>
      mergeBranch({
        fromBranch: branchName,
        toBranch: previewBranchName,
      }),
    {
      onSuccess,
      onError: (error: any) => {
        logError(error);
        setIsResetModalOpened(true);
      },
    },
  );

  const {mutate: pushCommit, isLoading: isPushingCommit} =
    usePushCommitMutation();

  const isLoading =
    isPushingCommit || isCreatingAndMergingPullRequest || isResettingHead;
  const canPreview = hasChangesToPreview || hasModifiedFiles;

  return (
    <>
      <Tooltip
        title="Nothing to preview. Please make & save some changes first."
        {...(isComparingPreviewChanges ||
        hasChangesToPreview ||
        hasModifiedFiles
          ? {visible: false}
          : {})}
      >
        <Button
          icon={<CloudUploadOutlined />}
          loading={isLoading}
          disabled={!canPreview}
          onClick={
            hasModifiedFiles
              ? () =>
                  pushCommit({
                    onSuccess: () => {
                      createAndMergePullRequest();
                      /**
                       * Invalidate the stalled state of publishable changes.
                       */
                      refetchComparePublishChanges();
                    },
                  })
              : () => createAndMergePullRequest()
          }
        >
          {isCreatingAndMergingPullRequest
            ? "Merging pull-request..."
            : isPushingCommit
            ? "Pushing commit..."
            : isResettingHead
            ? "Resetting head..."
            : `Preview on ${previewEnvName}`}
        </Button>
      </Tooltip>
      <Modal
        title="Conflicting changes."
        okType="danger"
        okText="Force Preview"
        visible={isResetModalOpened}
        confirmLoading={isResettingHead}
        onOk={async () => {
          await resetPreviewHead();
          setIsResetModalOpened(false);
        }}
        onCancel={() => setIsResetModalOpened(false)}
      >
        <p>A conflicting changes are currently previewed.</p>
      </Modal>
    </>
  );
};
