import React from "react";
import {styled} from "@reside/ui";
import {debounce} from "lodash";
import {ParagraphNode} from "@reside/forms";
import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";
import {Override} from "../../model/schemaTypes";

type Props = Readonly<{
  isFocused?: boolean;
  node: Override<ParagraphNode, {id: string}>;
}> &
  AtomProp;

export const EditableParagraph = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
      styledElement={StyledParagraphCSS}
    />
  );
};

const StyledParagraphCSS = styled.div`
  .bangle-editor > p {
    margin-top: 0.5em;
    margin-bottom: 0.75em;
    font-size: 1.25em;
    line-height: 1.75em;
    padding: 0px 7px;
  }

  .ant-tag {
    font-size: 1em;
    padding: 0.3em;
  }
`;
