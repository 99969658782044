import {Octokit} from "@octokit/rest";
import {repositoryConfig} from "../config";
import {isFeatureBranch} from "../model/repository";

const {repo, owner} = repositoryConfig;

let octokit: Octokit;

export type GithubUser = {
  login: string;
  name: string | null;
  email: string | null;
  avatar_url: string;
};

export const initializeOctokit = (token: string) => {
  octokit = new Octokit({
    auth: token,
  });
};

export const getUserProfile = async () => {
  const {data: user} = await octokit.rest.users.getAuthenticated();

  if (!user.email) {
    let emails;
    try {
      const {data} = await octokit.rest.users.listEmailsForAuthenticated();
      emails = data;
    } catch {
      throw new Error(
        "Please enable 'user:email' scope for your personal access token.",
      );
    }

    const primaryEmail = emails.find(email => email.primary);

    if (!primaryEmail) {
      throw new Error(
        "No primary email configured for your account. Please configure one.",
      );
    }

    return {...user, email: primaryEmail.email};
  }

  return user;
};

export const getBranches = async (): Promise<ReadonlyArray<string>> => {
  const {data} = await octokit.rest.repos.listBranches({
    owner,
    repo,
  });

  return data.map(branch => branch.name).filter(isFeatureBranch);
};

export const compareChanges = ({
  fromBranch,
  toBranch,
}: {
  fromBranch: string;
  toBranch: string;
}) =>
  octokit.rest.repos.compareCommits({
    owner,
    repo,
    base: toBranch,
    head: fromBranch,
  });

export const mergeBranch = ({
  fromBranch,
  toBranch,
}: {
  fromBranch: string;
  toBranch: string;
}) =>
  octokit.rest.repos.merge({
    owner,
    repo,
    base: toBranch,
    head: fromBranch,
  });
