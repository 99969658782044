import {BangleEditorState} from "@bangle.dev/core";
import {Node as ProsemirrorNode} from "prosemirror-model";
import {MarkdownSerializerState} from "prosemirror-markdown";

const ANSWER_NODE_NAME = "answer";

const domSerializationHelpers = () => {
  return {
    toDOM: (node: ProsemirrorNode) => [
      "span",
      {
        id: node.attrs.id,
        value: node.attrs.value,
        label: node.attrs.label,
        class: "ant-tag ant-tag-blue",
        contentEditable: false,
      },
      node.attrs.label || node.attrs.id,
    ],
    parseDOM: [
      {
        tag: "span.ant-tag",
        getAttrs: (dom: HTMLLIElement) => {
          return {
            id: dom.getAttribute("id"),
            value: dom.getAttribute("value"),
            label: dom.getAttribute("label"),
          };
        },
      },
    ],
  };
};

export const answerSpec = () => {
  return {
    type: "node",
    name: ANSWER_NODE_NAME,
    schema: {
      attrs: {
        id: {default: ""},
        value: {default: ""},
        label: {default: ""},
      },
      inline: true,
      group: "inline",
      draggable: true,
      atom: true,
      ...domSerializationHelpers(),
    },
    markdown: {
      toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
        state.write(`[[${node.attrs.id}]]`);
        state.renderInline(node);
      },
      parseMarkdown: {
        answer: {
          node: ANSWER_NODE_NAME,
          getAttrs: (token: any) => ({
            id: token.attrGet("id"),
            value: token.attrGet("value"),
            label: token.attrGet("label"),
          }),
        },
      },
    },
  };
};

export type CreateAnswerCommand = (
  attrs: any,
) => (state: typeof BangleEditorState, dispatch: any) => boolean;

export const createAnswerNode = (attrs: any) => {
  return (state: typeof BangleEditorState, dispatch: any) => {
    const type = state.schema.nodes.answer;
    dispatch(state.tr.replaceSelectionWith(type.create(attrs)));
    return true;
  };
};
