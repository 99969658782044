import React from "react";
import {styled} from "@reside/ui";
import {debounce} from "lodash";
import {TitleNode} from "@reside/forms";
import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";
import {Override} from "../../model/schemaTypes";

type Props = Readonly<{
  isFocused?: boolean;
  node: Override<TitleNode, {id: string}>;
}> &
  AtomProp;

export const EditableTitle = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
      styledElement={StyledTitleCSS}
    />
  );
};

const StyledTitleCSS = styled.div`
  .bangle-editor > p {
    font-weight: 600;
    font-size: 2.625em;
    line-height: 1.5em;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 0px 7px;
  }

  .ant-tag {
    font-size: 1em;
    padding: 0.3em;
  }
`;
