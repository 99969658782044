import {
  atom,
  atomFamily,
  DefaultValue,
  RecoilState,
  selectorFamily,
  useRecoilCallback,
} from "recoil";

import {SourceNodes, TemplateNodes} from "../schemaTypes";
import {activeSlideAtomSelector} from "../editor";
import {validateNode} from "./validationUtils";
import {
  fieldArraysSelector,
  templatesRefsCountSelector,
  templatesRefsSelector,
} from "../answers";
import {deatomizeNodesAsync, templateTypeAtom} from "../template";

export type ValidationErrors = ReadonlyArray<string>;

export type SlideValidationErrors = ReadonlyArray<{
  errors: ValidationErrors;
  node: TemplateNodes;
}>;

const nodeErrorsAtomFamily = atomFamily<ValidationErrors, string>({
  key: "nodeErrorsAtomFamily ",
  default: [],
});

const touchedNodesFamily = atomFamily<boolean, string>({
  key: "touchedNodesFamily",
  default: false,
});

const touchedNodeIdsAtom = atom<string[]>({
  key: "touchedNodeIdsAtom",
  default: [],
});

export const touchedNodesFamilySelector = selectorFamily({
  key: "touchedNodesFamilySelector",
  get:
    (id: string) =>
    ({get}) =>
      get(touchedNodesFamily(id)),
  set:
    (id: string) =>
    ({set}) => {
      set(touchedNodesFamily(id), true);
      set(touchedNodeIdsAtom, previous => [...previous, id]);
    },
});

const slideErrorsAtomFamily = atomFamily<SlideValidationErrors, string>({
  key: "slideErrorsAtomFamily",
  default: [],
});

export const validationErrorIdsAtom = atom<string[]>({
  key: "validationErrorIdsAtom",
  default: [],
});

export const invalidSlideIdsAtom = atom<string[]>({
  key: "invalidSlideIdsAtom",
  default: [],
});

export const nodeErrorsSelector = selectorFamily({
  key: "nodeErrorsSelector",
  get:
    (id: string) =>
    ({get}) =>
      get(nodeErrorsAtomFamily(id)),
  set:
    (id: string) =>
    ({set, get}, newValidationErrors: ValidationErrors | DefaultValue) => {
      set(nodeErrorsAtomFamily(id), newValidationErrors);

      if ((newValidationErrors as ValidationErrors).length === 0) {
        set(validationErrorIdsAtom, prevIds =>
          prevIds.filter(currentId => currentId !== id),
        );
      } else if (!get(validationErrorIdsAtom).includes(id)) {
        set(validationErrorIdsAtom, prevIds => [...prevIds, id]);
      }
    },
});

export const validationSlideAtomFamilySelector = selectorFamily({
  key: "validationSlideAtomFamilySelector",
  get:
    (slideId: string) =>
    ({get}) =>
      get(slideErrorsAtomFamily(slideId)),
  set:
    (slideId: string) =>
    ({set, get}, slideErrors: SlideValidationErrors | DefaultValue) => {
      set(slideErrorsAtomFamily(slideId), slideErrors);

      if ((slideErrors as SlideValidationErrors).length === 0) {
        set(invalidSlideIdsAtom, previousSlideIds =>
          previousSlideIds.filter(currentSlideId => currentSlideId !== slideId),
        );
      } else if (
        !get(invalidSlideIdsAtom).find(
          currentSlideId => currentSlideId === slideId,
        )
      ) {
        set(invalidSlideIdsAtom, previousSlideIds => [
          ...previousSlideIds,
          slideId,
        ]);
      }
    },
});

export const useResetValidations = () =>
  useRecoilCallback(({snapshot, reset}) => async () => {
    const validationErrorIds = await snapshot.getPromise(
      validationErrorIdsAtom,
    );
    const invalidSlideIds = await snapshot.getPromise(invalidSlideIdsAtom);
    const touchedNodeIds = await snapshot.getPromise(touchedNodeIdsAtom);
    validationErrorIds.forEach(validationErrorId => {
      reset(nodeErrorsSelector(validationErrorId));
    });
    invalidSlideIds.forEach(invalidSlideId =>
      reset(validationSlideAtomFamilySelector(invalidSlideId)),
    );
    touchedNodeIds.forEach(validationErrorId => {
      reset(touchedNodesFamily(validationErrorId));
    });
    reset(validationErrorIdsAtom);
    reset(invalidSlideIdsAtom);
    reset(touchedNodeIdsAtom);
  });

export const useValidateAtom = (
  atom: RecoilState<TemplateNodes | SourceNodes>,
  currentActiveSlide?: TemplateNodes,
  isArrayFieldScoped = false,
) =>
  useRecoilCallback(({snapshot, set}) => async () => {
    const [activeSlideAtom] = await snapshot.getPromise(
      activeSlideAtomSelector,
    );
    const activeSlide =
      currentActiveSlide ?? (await snapshot.getPromise(activeSlideAtom));
    const templateRefs = await snapshot.getPromise(templatesRefsSelector);
    const templateRefsCount = await snapshot.getPromise(
      templatesRefsCountSelector,
    );
    const fieldArrays = await snapshot.getPromise(fieldArraysSelector);

    const [deatomizedNode] = await deatomizeNodesAsync(
      [atom],
      snapshot.getPromise,
    );

    const templateType = await snapshot.getPromise(templateTypeAtom);

    const nodeErrors = validateNode(
      deatomizedNode,
      templateRefs,
      fieldArrays,
      templateRefsCount,
      templateType,
      isArrayFieldScoped,
    );
    set(nodeErrorsSelector(deatomizedNode.id), nodeErrors);
    set(validationSlideAtomFamilySelector(activeSlide.id), slideErrors => [
      ...slideErrors.filter(error => error.node.id !== deatomizedNode.id),
      ...nodeErrors.map(() => ({
        errors: nodeErrors,
        node: deatomizedNode,
      })),
    ]);
  });

export const useRemoveValidationErrors = (node: SourceNodes) =>
  useRecoilCallback(({snapshot, set}) => async () => {
    const templateRefs = await snapshot.getPromise(templatesRefsSelector);
    const templateRefsCount = await snapshot.getPromise(
      templatesRefsCountSelector,
    );
    const [activeSlideAtom] = await snapshot.getPromise(
      activeSlideAtomSelector,
    );
    const activeSlide = await snapshot.getPromise(activeSlideAtom);

    const removeValidationErrors = (nodes: ReadonlyArray<SourceNodes>) => {
      nodes?.forEach(async (node: any) => {
        const deatomizedNode = (await snapshot.getPromise(node)) as any;
        set(nodeErrorsSelector(deatomizedNode.id), []);

        set(validationSlideAtomFamilySelector(activeSlide.id), previousErrors =>
          previousErrors.filter(
            currentError => currentError.node.id !== deatomizedNode.id,
          ),
        );
        if (deatomizedNode.children) {
          removeValidationErrors(deatomizedNode.children);
        } else if (deatomizedNode.items) {
          removeValidationErrors(deatomizedNode.items);
        } else if (deatomizedNode.reference?.choices) {
          removeValidationErrors(deatomizedNode.reference.choices);
        }
      });
    };

    const templateType = await snapshot.getPromise(templateTypeAtom);
    const fieldArrays = await snapshot.getPromise(fieldArraysSelector);
    const validationErrors = validateNode(
      node,
      templateRefs,
      fieldArrays,
      templateRefsCount,
      templateType,
    );
    set(nodeErrorsSelector(node.id), validationErrors);

    set(validationSlideAtomFamilySelector(activeSlide.id), slideErrors =>
      slideErrors.filter(currentError => currentError.node.id !== node.id),
    );

    removeValidationErrors(node.children);
  });
