import React from "react";
import {useRecoilValue, useResetRecoilState} from "recoil";
import {useQuery, useMutation} from "react-query";
import {Skeleton, Button, Typography, Result} from "antd";

import {
  branchNameAtom,
  isFeatureBranchSelector,
  userConfigSelector,
} from "../../model/repository";
import {TemplateTable} from "../../atoms/template-table";
import {SlideCard} from "../../atoms/slide-card";
import {
  checkoutBranch,
  configureGitUser,
  isGitCheckoutConflictError,
  pullBranch,
} from "../../services/GitService";
import {logError} from "../../logging";
import {PageLayout} from "../../atoms/page-layout";
import {publishBranchName} from "../../config";
import {useInitializeRepo} from "../../model/editor";
import {compareChanges, mergeBranch} from "../../services/GithubService";

const {Text} = Typography;

export const SyncRepositoryPage = () => {
  const branchName = useRecoilValue(branchNameAtom);
  const resetBranchName = useResetRecoilState(branchNameAtom);
  const isFeatureBranch = useRecoilValue(isFeatureBranchSelector);
  const userConfig = useRecoilValue(userConfigSelector);
  const onInitialized = useInitializeRepo();

  const {isFetching: isPullingRepo, error: pullError} = useQuery(
    ["pullBranch", branchName],
    async ({queryKey: [, branchName]}: any) => {
      /**
       * Set global git user before pulling a branch.
       * This is required when the initial cloning was interrupted,
       * or when different user logged in to the same local copy.
       */
      await configureGitUser(userConfig);

      if (isFeatureBranch) {
        const {
          data: {files},
        } = await compareChanges({
          fromBranch: publishBranchName,
          toBranch: branchName,
        });

        // Merge published changes If the feature branch is behind
        if (files && files.length > 0) {
          await mergeBranch({
            fromBranch: publishBranchName,
            toBranch: branchName,
          });
        }
      }

      await pullBranch(branchName);
    },
    {
      enabled: !!branchName,
      onSuccess: () => {
        onInitialized();
      },
      onError: error => {
        logError({error});
      },
    },
  );

  const {mutate: forceCheckoutBranch, isLoading: isForceCheckoutBranchLoading} =
    useMutation(
      async (branchName: string) => {
        await checkoutBranch(branchName);
        await pullBranch(branchName);
      },
      {
        onSuccess: onInitialized,
        onError: error => {
          logError({error});
        },
      },
    );

  return (
    <PageLayout leftSider={<TemplateTable />}>
      <SlideCard>
        <div style={{textAlign: "center"}}>
          <Skeleton />
          <div style={{padding: 40}}>
            {isPullingRepo ? (
              <Result
                title={
                  <>
                    Pulling latest changes for <Text code>{branchName}</Text>
                  </>
                }
                subTitle="Please wait."
              />
            ) : pullError ? (
              <Result
                status="error"
                title={
                  <>
                    Failed to sync branch <Text code>{branchName}</Text>
                  </>
                }
                subTitle={(pullError as any).message}
                extra={
                  isGitCheckoutConflictError(pullError)
                    ? [
                        <Button
                          key="force"
                          ghost
                          danger
                          loading={isForceCheckoutBranchLoading}
                          onClick={() => forceCheckoutBranch(branchName)}
                        >
                          Discard local changes
                        </Button>,
                        <Button
                          key="continue"
                          disabled={isForceCheckoutBranchLoading}
                          onClick={onInitialized}
                        >
                          Continue to local changes
                        </Button>,
                      ]
                    : [
                        <Button
                          ghost
                          type="primary"
                          onClick={async () => {
                            await checkoutBranch(publishBranchName);

                            // Go back to 'clone' phase
                            resetBranchName();
                          }}
                        >
                          Checkout {publishBranchName} branch
                        </Button>,
                      ]
                }
              />
            ) : (
              <></>
            )}
          </div>
          <Skeleton />
        </div>
      </SlideCard>
    </PageLayout>
  );
};
