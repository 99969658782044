// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
import React, {useState} from "react";
import {useEditorState, BangleEditor} from "@bangle.dev/react";
import {corePlugins, coreSpec} from "@bangle.dev/core/utils/core-components";
import {Plugin, SpecRegistry} from "@bangle.dev/core";
import {tableMarkdownItPlugin} from "@bangle.dev/markdown/table-markdown-it-plugin";
import {todoListMarkdownItPlugin} from "@bangle.dev/markdown/todo-list-markdown-it-plugin";
import {BangleEditorState} from "@bangle.dev/core";
import {markdownParser, markdownSerializer} from "@bangle.dev/markdown";
import "@bangle.dev/core/style.css";
import "@bangle.dev/react-menu/style.css";
import markdownIt from "markdown-it";
import {Node as ProsemirrorNode} from "prosemirror-model";
import {ADAPTABLE_TEXT_CLASSNAME} from "@reside/ui";
import {EditorView} from "prosemirror-view";
import {answerSpec} from "./Answer";
import {markdownAnswers} from "./AnswerMarkdown";
import {MarkdownEditorMenu} from "../markdown-editor-menu";
import {TreeDataItem} from "../markdown-editor-menu/AnswerSelect";
import {AnswerType} from "../../model/markdown/MarkdownEditorModel";

type Answers = Record<string, AnswerType>;

export type Props = Readonly<{
  id?: string;
  defaultValue?: string;
  answers?: Answers;
  treeData: TreeDataItem[];
  onChange: (value: () => string) => void;
  hasTopMenu?: boolean;
  styledElement: any;
}>;

export const MarkdownEditor = ({
  id = "",
  defaultValue,
  answers,
  treeData,
  onChange,
  hasTopMenu,
  styledElement,
  ...props
}: Props) => {
  const [editor, setEditor] = useState();
  const specRegistry = new SpecRegistry([...coreSpec(), answerSpec()]);
  const serializer = markdownSerializer(specRegistry);
  const parser = markdownParser(specRegistry, markdownItTokenizer(answers));

  const updateValueOnChange = () => ({
    update: (view: EditorView, prevState: typeof BangleEditorState) => {
      if (!view.state.doc.eq(prevState.doc)) {
        onChange(() => {
          return serializer.serialize(view.state.doc);
        });
      }
    },
  });

  const editorState = useEditorState({
    specRegistry,
    plugins: () => [new Plugin({view: updateValueOnChange}), ...corePlugins()],
    initialValue: parser.parse(defaultValue),
  });

  const StyledElement = styledElement;
  return (
    <>
      <MarkdownEditorMenu
        hasTopMenu={hasTopMenu}
        treeData={treeData}
        editor={editor}
      />
      <StyledElement>
        <BangleEditor
          className={ADAPTABLE_TEXT_CLASSNAME}
          state={editorState}
          onReady={setEditor}
        />
      </StyledElement>
    </>
  );
};

export type RenderNodeViewsProps = Readonly<{
  node: ProsemirrorNode;
  children: JSX.Element;
}>;

const markdownItTokenizer = (answers?: Answers) =>
  markdownIt("default", {
    breaks: false,
    html: false,
  })
    .use(todoListMarkdownItPlugin)
    .use(tableMarkdownItPlugin)
    .use(markdownAnswers(answers));
