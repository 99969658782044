import React from "react";
import {RecoilState, useRecoilCallback} from "recoil";
import * as immutable from "object-path-immutable";
import {take, findIndex, findLastIndex} from "lodash";
import {Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

import {styled} from "@reside/ui";

import {useTemplateAtomState} from "../../../../model/template";
import {ActionButton} from "./ActionButton";
import {activeSlidePathAtom, AtomProp} from "../../../../model/editor";
import {GroupNode, TemplateNodes} from "../../../../model/schemaTypes";
import {
  isSectionGroupNode,
  isSectionNode,
  isSlideNode,
} from "../../../../utils";
import {isSameSection} from "../../utils/utils";

export const DeleteGroupButton = ({
  indexPath,
  atom,
  parentAtom,
  disabled,
}: {
  indexPath: ReadonlyArray<number>;
  parentAtom: RecoilState<GroupNode>;
  disabled: boolean;
} & AtomProp<GroupNode>) => {
  const [parent, setParent] = useTemplateAtomState(parentAtom);

  const handleDelete = useRecoilCallback(({snapshot, set}) => async () => {
    const node = await snapshot.getPromise(atom);
    const activeSlidePath = await snapshot.getPromise(activeSlidePathAtom);

    const sectionChildren = await Promise.all(
      (parent.children as any as RecoilState<TemplateNodes>[]).map(
        snapshot.getPromise,
      ),
    );

    const getNextActiveSlidePath = () => {
      if (isSlideNode(node)) {
        if (isSameSection(indexPath, activeSlidePath)) {
          if (indexPath[2] < activeSlidePath[2]) {
            return [
              ...take(activeSlidePath, 2),
              activeSlidePath[2] === 0 ? 0 : activeSlidePath[2] - 1,
            ];
          } else if (indexPath[2] === activeSlidePath[2]) {
            /**
             * If active slide is removed, we either lookup index of prev slide or next slide.
             * We can't jump to previous item, as that might be PDF which would mean invalid slide path.
             * We assume there is always either prev or next slide, as we don't allow removing the last slide in the section.
             */
            const prevIndex =
              activeSlidePath[2] === 0
                ? -1
                : findLastIndex(
                    sectionChildren,
                    isSlideNode,
                    activeSlidePath[2] - 1,
                  );

            return [
              ...take(activeSlidePath, 2),
              prevIndex >= 0
                ? prevIndex
                : findIndex(
                    sectionChildren,
                    isSlideNode,
                    activeSlidePath[2] + 1,
                  ) - 1,
            ];
          }
        }
      } else if (isSectionNode(node)) {
        if (indexPath[0] === activeSlidePath[0]) {
          if (indexPath[1] <= activeSlidePath[1]) {
            return [
              activeSlidePath[0],
              activeSlidePath[1] === 0 ? 0 : activeSlidePath[1] - 1,
              0,
            ];
          }
        }
      } else if (isSectionGroupNode(node)) {
        if (indexPath[0] <= activeSlidePath[0]) {
          return [activeSlidePath[0] === 0 ? 0 : activeSlidePath[0] - 1, 0, 0];
        }
      }

      return activeSlidePath;
    };

    if (
      window.confirm(
        `Do you want remove this ${node.type} with all its child nodes?`,
      )
    ) {
      const nextActiveSlidePath = getNextActiveSlidePath();

      if (nextActiveSlidePath) {
        set(activeSlidePathAtom, nextActiveSlidePath);
      }

      setParent({
        ...parent,
        children: immutable.del(
          parent.children,
          `${[...indexPath].pop()}`,
        ) as any,
      });
    }
  });

  return (
    <Tooltip
      title="Can not delete component with common parent"
      {...(disabled ? {} : {visible: false})}
    >
      <DeleteButton
        data-testid="delete-group-button"
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();

          handleDelete();
        }}
        icon={<DeleteOutlined />}
      />
    </Tooltip>
  );
};

const DeleteButton = styled(ActionButton)`
  right: 50px;
`;
