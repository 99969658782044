var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef, forwardRef, useState, useEffect, useMemo, } from "react";
import DayPicker from "react-day-picker/DayPicker";
import { format } from "date-fns";
import { styled } from "../theme";
import { YearMonthPicker } from "../year-month-picker";
import { DATE_FORMAT } from "./date";
export var DatePicker = forwardRef(function (_a, ref) {
    var date = _a.date, range = _a.range, style = _a.style, tabIndex = _a.tabIndex, testId = _a.testId, onChange = _a.onChange, className = _a.className;
    var pickerRef = useRef();
    /**
     * We keep clone of the date, so we don't rely on parent storing the date and providing it back on every change.
     * Required to render the YearMonthSelect correctly.
     */
    var _b = useState(date), dateClone = _b[0], setDateClone = _b[1];
    var _c = useState(function () { return date !== null && date !== void 0 ? date : new Date(); }), yearMonthDateClone = _c[0], setYearMonthDateClone = _c[1];
    var day = format(date, DATE_FORMAT.ISO_DATE);
    // prevent element loosing focus for select elements https://github.com/gpbl/react-day-picker/issues/839
    var captionElement = useMemo(function () {
        return function (_a) {
            var date = _a.date;
            return (React.createElement(CaptionForm, { className: "DayPicker-Caption" },
                React.createElement(YearMonthPicker, { date: date, range: range, tabIndex: tabIndex, onChange: function (date) {
                        setYearMonthDateClone(date);
                    } })));
        };
    }, [range, tabIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(function () {
        setDateClone(date);
        setYearMonthDateClone(date);
    }, [date, day]);
    return (React.createElement(Container, { ref: ref, style: style, "data-test-id": testId && "datepicker-" + testId, className: className },
        React.createElement(DayPicker, { tabIndex: tabIndex, ref: pickerRef, month: yearMonthDateClone, selectedDays: [dateClone], navbarElement: function () { return null; }, onDayClick: function (date) {
                setDateClone(date);
                setYearMonthDateClone(date);
                onChange(date, "day");
            }, onMonthChange: function (date) {
                setYearMonthDateClone(date);
            }, captionElement: captionElement })));
});
DatePicker.defaultProps = {
    tabIndex: 0,
};
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .DayPickerInput {\n    width: 100%;\n  }\n\n  .DayPicker {\n    background: ", ";\n    border: 1px solid ", ";\n  }\n\n  .DayPicker-Wrapper:focus {\n    outline: none;\n  }\n\n  .DayPicker-Weekday {\n    color: ", ";\n  }\n\n  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {\n    background: ", ";\n  }\n\n  .DayPicker-Day--today {\n    color: ", ";\n  }\n\n  .DayPicker:not(.DayPicker--interactionDisabled) {\n    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {\n      background: ", ";\n    }\n  }\n"], ["\n  .DayPickerInput {\n    width: 100%;\n  }\n\n  .DayPicker {\n    background: ", ";\n    border: 1px solid ", ";\n  }\n\n  .DayPicker-Wrapper:focus {\n    outline: none;\n  }\n\n  .DayPicker-Weekday {\n    color: ", ";\n  }\n\n  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {\n    background: ", ";\n  }\n\n  .DayPicker-Day--today {\n    color: ", ";\n  }\n\n  .DayPicker:not(.DayPicker--interactionDisabled) {\n    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {\n      background: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.gray80;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.gray100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.deepOrange100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.lightBlue10;
});
export var CaptionForm = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: table-caption;\n  margin-bottom: 15px;\n  padding: 0;\n  font-size: 14px;\n"], ["\n  display: table-caption;\n  margin-bottom: 15px;\n  padding: 0;\n  font-size: 14px;\n"])));
var templateObject_1, templateObject_2;
