import {
  atom,
  RecoilState,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import {SourceSectionGroup, TemplateNodes, Template} from "../schemaTypes";
import {TemplateType} from "../../constants";
import {atomizeNodes, deatomizeNodes} from "./templateUtils";
import {useResetValidations} from "../validation";
import {focusedAtomAtom} from "../editor";
import {activeSlidePathAtom} from "../editor";
import {readTemplate} from "../../services/FileService";
import {logError} from "../../logging";
import {message} from "antd";
import {useSetPriorTemplates} from "../answers";

/**
 * ATOMS
 */
export const templateTypeAtom = atom<TemplateType>({
  key: "templateTypeAtom",
  default: TemplateType.RESIDENT_EXPERIENCE,
});

export const templateNameAtom = atom<string | undefined>({
  key: "templateName",
  default: undefined,
});

export const atomizedTemplateAtom = atom<RecoilState<TemplateNodes>[]>({
  key: "atomizedTemplate",
  default: [],
});

export const isTemplateDirtyAtom = atom({
  key: "isTemplateDirty",
  default: false,
});

export const lastTemplateSaveAtAtom = atom<string>({
  key: "lastTemplateSaveAt",
  default: new Date().toISOString(),
});

const persistedTemplateAtom = atom<Template>({
  key: "persistedTemplate",
  default: [],
});

export const availableTemplatesAtom = atom<ReadonlyArray<string>>({
  key: "availableTemplates",
  default: [],
});

/**
 * SELECTORS
 */
export const isTemplateLoadedSelector = selector({
  key: "isTemplateLoaded",
  get: ({get}) => {
    const template = get(atomizedTemplateAtom);

    return template.length > 0;
  },
});

export const templateSelector = selector<ReadonlyArray<SourceSectionGroup>>({
  key: "template",
  get: ({get}) =>
    deatomizeNodes(
      get(atomizedTemplateAtom),
      get,
    ) as any as ReadonlyArray<SourceSectionGroup>,
});

export const deatomizedFocusedNodeSelector = selector({
  key: "deatomizedFocusedNodeSelector",
  get: ({get}) => {
    const focusedAtom = get(focusedAtomAtom);
    if (focusedAtom) {
      const [deatomizedFocusedAtom] = deatomizeNodes([focusedAtom], get);
      return deatomizedFocusedAtom;
    }
  },
});

const isTemplateTypeSelector = selectorFamily<boolean, TemplateType>({
  key: "isTemplateType",
  get:
    type =>
    ({get}) => {
      const templateType = get(templateTypeAtom);

      return type === templateType;
    },
});

export const persistedTemplateSelector = selector<Template>({
  key: "persistedTemplateSelector",
  get: ({get}) => get(persistedTemplateAtom),
  set: ({set, get, reset}, template) => {
    // @ts-ignore
    const atomizedTemplate = atomizeNodes(template);

    /**
     * We reset the atoms to default state, because in case when some changes were discarded, they are still in recoil store,
     * so when the 'discarded' template is opened, we must go back to initial state by resetting atoms.
     */
    const resetAtoms = (
      atoms: ReadonlyArray<RecoilState<TemplateNodes>>,
    ): void => {
      atoms.forEach(atom => {
        const node = get(atom) as any;
        reset(atom);

        return node.children
          ? resetAtoms(node.children)
          : node.items
          ? resetAtoms(node.items)
          : node.reference?.choices
          ? resetAtoms(node.reference.choices)
          : undefined;
      });
    };

    resetAtoms(atomizedTemplate);

    set(atomizedTemplateAtom, atomizedTemplate);
    set(persistedTemplateAtom, template);
  },
});

/**
 * Pseudo atom, to create layer above section-groups so we can use the same interface for deleting section-groups as we do for sections or slides.
 * TODO: the type could be part of TemplateNodes
 */
export const rootAtom = selector<{
  type: TemplateType;
  children: RecoilState<TemplateNodes>[];
}>({
  key: "rootAtom",
  get: ({get}) => {
    return {
      type: get(templateTypeAtom),
      children: get(atomizedTemplateAtom),
    };
  },
  set: ({set}, root: any) => {
    set(atomizedTemplateAtom, root.children);
  },
});

/**
 * HOOKS
 */
export const useIsResidentExperienceTemplateValue = () =>
  useRecoilValue(isTemplateTypeSelector(TemplateType.RESIDENT_EXPERIENCE));

export const useIsFacilitySettingsTemplateValue = () =>
  useRecoilValue(isTemplateTypeSelector(TemplateType.FACILITY_SETTINGS));

export const useIsPreludeTemplateValue = () =>
  useRecoilValue(isTemplateTypeSelector(TemplateType.PRELUDE));

export const useSetTemplateDirty = () => {
  const setIsTemplateDirty = useSetRecoilState(isTemplateDirtyAtom);

  return () => setIsTemplateDirty(true);
};

export const useTemplateAtomState = <T = TemplateNodes>(
  atom: RecoilState<T>,
) => {
  const [node, setNode] = useRecoilState(atom);
  const setIsTemplateDirty = useSetRecoilState(isTemplateDirtyAtom);

  return [
    node,
    (data: T) => {
      setIsTemplateDirty(true);
      setNode(data);
    },
  ] as [T, SetterOrUpdater<T>];
};

export const useSetPersistedTemplate = () => {
  const resetIsTemplateDirty = useResetRecoilState(isTemplateDirtyAtom);
  const setTemplatePersisted = useSetRecoilState(persistedTemplateSelector);
  const setTemplateNameAtom = useSetRecoilState(templateNameAtom);
  const setTemplateTypeAtom = useSetRecoilState(templateTypeAtom);
  const resetFocusedAtom = useResetRecoilState(focusedAtomAtom);
  const resetValidations = useResetValidations();
  const resetActiveSlidePath = useResetRecoilState(activeSlidePathAtom);

  return ({
    template,
    templateName,
    templateType = TemplateType.RESIDENT_EXPERIENCE,
  }: {
    template: Template;
    templateName?: string;
    templateType?: TemplateType;
  }) => {
    resetIsTemplateDirty();
    resetValidations();
    resetFocusedAtom();
    resetActiveSlidePath();
    setTemplateNameAtom(templateName);
    setTemplatePersisted(template);
    setTemplateTypeAtom(templateType);
  };
};

export const useResetPersistedTemplate = () => {
  const resetAtomizedTemplateAtom = useResetRecoilState(atomizedTemplateAtom);
  const resetPersistedTemplateAtom = useResetRecoilState(persistedTemplateAtom);
  const resetTemplateNameAtom = useResetRecoilState(templateNameAtom);
  const resetTemplateTypeAtom = useResetRecoilState(templateTypeAtom);
  const resetIsTemplateDirty = useResetRecoilState(isTemplateDirtyAtom);
  const resetFocusedAtom = useResetRecoilState(focusedAtomAtom);
  const resetValidations = useResetValidations();

  return () => {
    resetAtomizedTemplateAtom();
    resetPersistedTemplateAtom();
    resetTemplateNameAtom();
    resetTemplateTypeAtom();
    resetIsTemplateDirty();
    resetValidations();
    resetFocusedAtom();
  };
};

export const useSwitchTemplate = () => {
  const isTemplateDirty = useRecoilValue(isTemplateDirtyAtom);
  const setPersistedTemplate = useSetPersistedTemplate();
  const setPriorTemplates = useSetPriorTemplates();

  return async (
    templateName: string,
    templateType = TemplateType.RESIDENT_EXPERIENCE,
  ) => {
    try {
      if (
        !isTemplateDirty ||
        window.confirm(
          "You have unsaved changes. Are you sure you want to continue without saving changes?",
        )
      ) {
        const template = await readTemplate(templateName, templateType);

        setPersistedTemplate({template, templateName, templateType});
        setPriorTemplates(templateName, templateType);
      }
    } catch (error) {
      logError({error});
      message.error("Failed to import template.");
    }
  };
};
