import React from "react";
import {useEditorViewContext} from "@bangle.dev/react";
import {TreeSelect} from "antd";
import {styled} from "@reside/ui";
import {createAnswerNode} from "../markdown-editor/Answer";

type Props = Readonly<{
  treeData: TreeDataItem[];
}>;

export type TreeDataItem = {
  id: string;
  title: string;
  value: string;
  selectable: boolean;
  children?: TreeDataItem[];
};

export const AnswerSelect = ({treeData}: Props) => {
  const view = useEditorViewContext();
  return (
    <StyledTreeSelect
      size="small"
      placeholder="Please select answer"
      showSearch /* TODO: Update ANTD to version where text search would work on title/label and not only value/id  */
      value={view.state.selection.node?.attrs?.value}
      onChange={(value, labelList, extra) => {
        const {props} = extra.triggerNode;
        const attrs = {
          id: props.id,
          value: props.value,
          label: props.title,
        };
        if (createAnswerNode(attrs)(view.state, view.dispatch)) {
          if (view.dispatch) {
            view.focus();
          }
        }
      }}
      treeData={treeData}
      treeDefaultExpandAll
    />
  );
};

const StyledTreeSelect = styled(TreeSelect)`
  min-width: 360px;
`;
