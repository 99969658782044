import React, {memo} from "react";
import {useToggle} from "react-use";
import {isEqual} from "lodash";
import {
  RecoilState,
  useRecoilValue,
  useSetRecoilState,
  waitForAll,
} from "recoil";
import {Draggable} from "react-beautiful-dnd";
import {Collapse} from "react-collapse";
import {Space, Avatar} from "antd";
import {orange, blue} from "@ant-design/colors";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  FunctionOutlined,
} from "@ant-design/icons";
import {styled} from "@reside/ui";
import {css} from "@emotion/react";

import {
  GroupNode,
  SourcePdfNode,
  TemplateNodes,
} from "../../../../model/schemaTypes";
import {
  activeSlidePathAtom,
  focusedAtomAtom,
  isSlidePathActiveSelector,
} from "../../../../model/editor";
import {
  isCommonImportedComponent,
  pathToKey,
  isGroupNode,
  isPdfNode,
  isSlideNode,
} from "../../../../utils";
import {NodeIcon} from "../../../node-icon";
import {NodeName} from "../../../node-name";
import {NodeDropList} from "../node-drop-list";
import {RenameGroupTitleButton} from "./RenameGroupTitleButton";
import {DeleteGroupButton} from "./DeleteGroupButton";
import {DownloadPdfButton} from "./DownloadPdfButton";
import {DeletePdfButton} from "./DeletePdfButton";
import {PdfSourceTemplateTag} from "./PdfSourceTemplateTag";
import {EditPdfMappersButton} from "./EditPdfMappersButton";
import {validationSlideAtomFamilySelector} from "../../../../model/validation";

// This should be in antd as GRAY-3 but is not...
const GRAY = "#f5f5f5";

type Props = Readonly<{
  atom: RecoilState<TemplateNodes>;
  parentAtom: RecoilState<TemplateNodes>;
  index: number;
  depthLevel: number;
  isInsideCommonComponent: boolean;
  indexPath: ReadonlyArray<number>;
  isActiveSlideChild: boolean;
  isFocused: boolean;
}>;

export const NodeDragItem = memo(
  ({
    atom,
    index,
    parentAtom,
    depthLevel,
    isInsideCommonComponent,
    indexPath,
    isActiveSlideChild,
    isFocused,
  }: Props) => {
    const node = useRecoilValue(atom);
    const parent = useRecoilValue(parentAtom);
    const parentChildren = useRecoilValue(waitForAll(parent.children)) as any;
    const setFocusedAtom = useSetRecoilState(focusedAtomAtom);
    const setActiveSlidePath = useSetRecoilState(activeSlidePathAtom);
    const isActiveSlide = useRecoilValue(isSlidePathActiveSelector(indexPath));
    const slideErrors = useRecoilValue(
      validationSlideAtomFamilySelector(node.id),
    );

    const isSlide = isSlideNode(node);
    const isGroup = isGroupNode(node);
    const isSlideChild = !isGroup;
    const [isExpanded, toggleExpanded] = useToggle(!isSlide);
    const slideHasErrors = isSlide && slideErrors.length > 0;
    const canExpand = node.children;
    const isCommonComponent =
      isCommonImportedComponent(node) || isInsideCommonComponent;
    const isActiveSlideOrChild = isActiveSlideChild || isActiveSlide;

    const isItemDisabled = !isSlide && isCommonComponent;
    const isOnlyChild = isGroup && parent.children?.length === 1;
    const isOnlyGroup = parentChildren.filter(isGroupNode).length === 1;
    const hideDeleteButton = isOnlyChild || isOnlyGroup;

    return (
      <Draggable
        isDragDisabled={isInsideCommonComponent || isOnlyChild}
        draggableId={node.id}
        index={index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Item
              data-testid={`list-item-${node.id}`}
              isActive={isFocused}
              depthLevel={depthLevel}
              isFocusable={isSlide || (isSlideChild && isActiveSlideOrChild)}
              onClick={event => {
                event.stopPropagation();

                if (isItemDisabled) {
                  return;
                }

                if (!isGroup && isActiveSlideChild) {
                  setFocusedAtom(atom);
                }

                if (isSlide) {
                  setActiveSlidePath(indexPath);
                  if (!isCommonComponent) {
                    setFocusedAtom(atom);
                  }
                }
              }}
              style={{
                backgroundColor: isActiveSlideOrChild
                  ? blue[0]
                  : isCommonComponent
                  ? orange[0]
                  : !isActiveSlideChild && !isGroup
                  ? GRAY
                  : "#fafafa",
              }}
            >
              <FlexRow>
                <Space style={{width: 30}}>
                  {canExpand &&
                    (isExpanded ? (
                      <CaretUpOutlined
                        onClick={event => {
                          event.stopPropagation();
                          toggleExpanded();
                        }}
                      />
                    ) : (
                      <CaretDownOutlined
                        onClick={event => {
                          event.stopPropagation();
                          toggleExpanded();
                        }}
                      />
                    ))}
                  {/* Empty span, so the <Space /> is rendered also for nodes without children, thus providing the same offset. */}
                  <span />
                </Space>
                <StyledSpace>
                  <NodeIcon node={node} />
                  {isPdfNode(node) && <PdfSourceTemplateTag node={node} />}
                  <StyledNodeName node={node} />
                  {isPdfNode(node) && node.mappers && (
                    <Space style={{position: "absolute", right: 20}}>
                      <FunctionOutlined />
                    </Space>
                  )}
                </StyledSpace>
                {isGroup && (
                  <AlignRight>
                    <RenameGroupTitleButton
                      atom={atom as RecoilState<GroupNode>}
                      disabled={isCommonComponent}
                    />
                    {!hideDeleteButton && (
                      <DeleteGroupButton
                        indexPath={indexPath}
                        atom={atom as RecoilState<GroupNode>}
                        parentAtom={parentAtom as RecoilState<GroupNode>}
                        disabled={isInsideCommonComponent}
                      />
                    )}
                    {slideHasErrors && (
                      <ErrorCountAvatar size="small">
                        {slideErrors.length}
                      </ErrorCountAvatar>
                    )}
                  </AlignRight>
                )}
                {isPdfNode(node) && (
                  <>
                    <EditPdfMappersButton
                      atom={atom as any as RecoilState<SourcePdfNode>}
                      node={node}
                    />
                    <DeletePdfButton
                      indexPath={indexPath}
                      parentAtom={parentAtom as RecoilState<GroupNode>}
                    />
                    <DownloadPdfButton node={node} />
                  </>
                )}
              </FlexRow>
            </Item>
            {canExpand && (
              <Collapse isOpened={isExpanded}>
                <NodeDropList
                  droppableId={pathToKey(indexPath)}
                  type={node.type}
                  atoms={node.children}
                  parentAtom={atom}
                  depthLevel={depthLevel + 1}
                  indexPath={indexPath}
                  isInsideCommonComponent={isCommonComponent}
                  isActiveSlideChild={isActiveSlideOrChild}
                  isDropDisabled={!isExpanded}
                />
              </Collapse>
            )}
          </div>
        )}
      </Draggable>
    );
  },
  /**
   * Custom function to diff the array of indexPath which cannot be compared shallowly.
   */
  (
    {atom: atomA, parentAtom: parentAtomA, ...propsA},
    {atom: atomB, parentAtom: parentAtomB, ...propsB},
  ) =>
    atomA === atomB && parentAtomA === parentAtomB && isEqual(propsA, propsB),
);

const StyledSpace = styled.div`
  width: 100%;
  margin-right: 0;
  padding: 10px 0;
  display: inline-flex;
  overflow: auto;
`;

const StyledNodeName = styled(NodeName)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
`;

const ErrorCountAvatar = styled(Avatar)`
  background-color: red;
  border: none;
  top: 8px;
  right: 10px;
  position: absolute;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlignRight = styled.div`
  float: right;
`;

const Item = styled.div<{
  isActive: boolean;
  depthLevel: number;
  isFocusable: boolean;
}>`
  position: relative;
  background: #fff;
  width: 100%;
  height: 42px;
  padding: 0 24px;
  padding-left: ${({depthLevel}) => 16 * depthLevel}px;
  border-top: none;

  .anticon-caret-up,
  .anticon-caret-down {
    padding: 14px 8px;
    &:hover {
      color: #1890ff;
    }
  }

  ${({isFocusable}) =>
    isFocusable &&
    css`
      &:hover {
        color: #1890ff;
        background: #e6f7ff;
    `}

  &:hover {
    button {
      display: block;
    }
  }

  ${({isActive}) =>
    isActive &&
    css`
      color: #1890ff;
      background: #e6f7ff;
      border-right: 2px solid #1890ff;
    `}
`;
